import React, { useState, useContext, useEffect } from "react";
import {
  Radio,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import APIContext from "../../services/api";
import { URLS } from "../../configuration/urls";
import ValidationMessage from "../../components/validationMessage";
import { ECAxiosAPI, EcAuthContext } from "@eclear-ag/ec-auth";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Payment() {
  const validationMessages = [
    {
      title: "Something went wrong",
      messages: [
        {
          text: "Your request could not be processed. Please try again later.",
          type: "error",
        },
      ],
    },
  ];

  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isApiErrorVisible, setIsApiErrorVisible] = useState(false);

  const { userData, checkUserData } = React.useContext(EcAuthContext);

  const { setCardIframeLink } = useContext(APIContext);

  const theme = useTheme();
  const XsMdScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const navigate = useNavigate();

  useEffect(() => {
    !userData?.userShopDetails?.primaryShop && checkUserData();
  }, []);

  // endPoint, data, headersObj, baseUrl

  const gotoStripePage = async (productId) => {
    setIsApiErrorVisible(false);

    ECAxiosAPI.post(`${URLS.EC_AUTH_BE()}${URLS.GENERATE_CHECKOUT_SESSION}`, {
      merchantId: userData?.companyDetails[0]?.merchantId,
      // shopId: userData?.userShopDetails?.primaryShop?.id,
      productId: productId,
      successfulUrl: `${window.location.origin}/setting-up-account`,
      cancelUrl: `${window.location.origin}/signup/select-payment-method`,
    })
      .then((res) => {
        setCardIframeLink(res);
        setIsApiErrorVisible(false);
        window.location.href = res;
      })
      .catch((err) => {
        console.log("err GENERATE_CHECKOUT_SESSION: ", err);
        setIsApiErrorVisible(true);
        setIsNextLoading(false);
      });
  };

  const payWithCreditCard = async () => {
    setIsNextLoading(true);
    await ECAxiosAPI.get(
      `${URLS.EC_AUTH_BE()}product/find-by-product-type/SPOT`
    )
      .then((res) => {
        console.log(res);
        return gotoStripePage(res.id);
      })
      .catch((err) => {
        setIsNextLoading(false);
        console.log("err find-by-product-type: ", err);
      });
  };

  const payWithInvoice = () => {
    navigate("/signup/pay-with-invoice");
  };

  return (
    <div
      style={{
        borderRadius: "4px",
        width: XsMdScreen ? "80%" : "50%",
      }}
      className="ec-box"
    >
      <div>
        <FormControl style={{ width: "100%" }}>
          <FormLabel
            id="payment-selection-title"
            style={{ padding: "12px 16px", fontWeight: 500, fontSize: "20px" }}
          >
            <Typography
              variant="p"
              style={{ fontWeight: 500, fontSize: "20px" }}
            >
              <FormattedMessage id="Select your payment method" />
            </Typography>
          </FormLabel>
          <Divider />
          <RadioGroup
            row
            name="payment-radio-buttons"
            value={paymentMethod}
            onChange={handleChange}
            style={{
              padding: "12px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{ width: "100%" }}>
                <FormControlLabel
                  className={`card-option ${
                    paymentMethod === "creditCard" && "active"
                  }`}
                  value="creditCard"
                  control={
                    <Radio
                      aria-label="Credit card"
                      icon={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            className="icon-fill-primary"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            fill="#FCFDFF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            className="icon-fill-primary"
                            fill-opacity=".8"
                          />
                          <rect
                            x="6"
                            y="6.5"
                            width="4"
                            height="4"
                            rx="2"
                            fill="#FCFDFF"
                          />
                          <rect
                            x="6"
                            y="6.5"
                            width="4"
                            height="4"
                            rx="2"
                            className="icon-fill-primary"
                            fill-opacity=".8"
                          />
                        </svg>
                      }
                    />
                  }
                  label={
                    <Typography variant="subtitle1" component="span">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2H0zm0 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H0zm3 5a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1z"
                          className="icon-fill-primary"
                        />
                      </svg>
                      <Typography
                        style={{
                          marginTop: "-5px",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage id="Credit Card" />
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{ width: "100%" }}>
                <FormControlLabel
                  className={`card-option ${
                    paymentMethod === "invoice" && "active"
                  }`}
                  value="invoice"
                  control={
                    <Radio
                      aria-label="Invoice"
                      icon={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            className="icon-fill-primary"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            fill="#FCFDFF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                            className="icon-fill-primary"
                            fill-opacity=".8"
                          />
                          <rect
                            x="6"
                            y="6.5"
                            width="4"
                            height="4"
                            rx="2"
                            fill="#FCFDFF"
                          />
                          <rect
                            x="6"
                            y="6.5"
                            width="4"
                            height="4"
                            rx="2"
                            className="icon-fill-primary"
                            fill-opacity=".8"
                          />
                        </svg>
                      }
                    />
                  }
                  label={
                    <Typography variant="subtitle1" component="span">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 14V2h8v7.5l2-1.667V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-1.333L10 14H2z"
                          className="icon-fill-primary"
                        />
                        <rect
                          x="3"
                          y="3"
                          width="3"
                          height="2"
                          rx=".25"
                          className="icon-fill-primary"
                        />
                        <path
                          d="M3 6h6v1H3V6zM3 8h6v1H3zM3 10h6v1H3zM3 12h6l-.5 1H3v-1z"
                          className="icon-fill-primary"
                        />
                        <path
                          d="m11.952 10.538.094-.084-.084.094-.01-.01z"
                          className="icon-stroke-primary"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                        <mask id="svpo2bqisa" fill="#fff">
                          <path d="m11.5 12.5-2 .5.5-2 1.5 1.5z" />
                        </mask>
                        <path
                          d="m9.5 13-1.94-.485a2 2 0 0 0 2.425 2.425L9.5 13zm2-.5.485 1.94a2 2 0 0 0 .93-3.354L11.5 12.5zM10 11l1.414-1.414a2 2 0 0 0-3.354.929L10 11zm-.015 3.94 2-.5-.97-3.88-2 .5.97 3.88zm2.93-3.854-1.5-1.5-2.83 2.828 1.5 1.5 2.83-2.828zm-1.475 2.4.5-2-3.88-.971-.5 2 3.88.97z"
                          className="icon-fill-primary"
                          mask="url(#svpo2bqisa)"
                        />
                      </svg>

                      <Typography
                        style={{
                          marginTop: "-5px",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage id="Invoice" />
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      <div
        style={{
          margin: "0px 16px 16px 16px",
        }}
      >
        <ValidationMessage
          visibility={isApiErrorVisible}
          setVisibility={setIsApiErrorVisible}
          messages={validationMessages}
        />
      </div>
      <Divider />
      <div
        style={{
          padding: "24px 28px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          aria-label="Back"
          style={{ textTransform: "none" }}
          disableElevation
          onClick={() => {
            navigate("/signup/?screen=user");
          }}
        >
          <FormattedMessage id="back" />
        </Button>
        <Button
          aria-label="Continue"
          variant="contained"
          startIcon={
            isNextLoading && (
              <CircularProgress style={{ width: "14px", height: "14px" }} />
            )
          }
          disabled={isNextLoading}
          onClick={() => {
            if (paymentMethod === "invoice") {
              payWithInvoice();
            } else {
              payWithCreditCard();
            }
          }}
          style={{ textTransform: "none" }}
          disableElevation
        >
          <FormattedMessage id="continue" />
        </Button>
      </div>
    </div>
  );
}

// import axios from "axios";
import { ECAxiosAPI } from "@eclear-ag/ec-auth";
import { createContext, useState } from "react";
import { URLS } from "../configuration/urls";

const APIContext = createContext({});

const api_url = URLS.EC_SPOT_BE();

export const APIProvider = ({ children }) => {
  // const isSpotEmpty = "empty";
  const [isSpotEmpty, setIsSpotEmpty] = useState(
    localStorage.getItem("isSpotEmpty")
  );

  const [cardIframeLink, setCardIframeLink] = useState("");

  const getData = async (endPoint, headersObj, baseUrl) => {
    const headers = {
      accept: "*/*",
      ...headersObj,
    };
    try {
      const res = await ECAxiosAPI.get(
        // `${api_url}${endPoint}`,
        `${typeof baseUrl === "undefined" ? api_url : baseUrl}${endPoint}`,
        { headers }
      );
      return res;
    } catch (error) {
      // console.log(error.response.status);
      return error.response.status;
      errorHandler(endPoint, error);
    }
  };

  const getTokenApi = async (code) => {
    try {
      const res = await ECAxiosAPI.post(
        `${api_url}${URLS.GENERATE_AUTH_TOKEN}`,
        {
          code,
        }
      );
      // await ECAxiosAPI({
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     },
      //     url: `${api_url}${endPoint}`,
      //     data: data
      // });
      return res;
    } catch (error) {
      errorHandler("getToken", error);
    }
  };

  const postData = async (endPoint, data, headersObj, baseUrl) => {
    const headers = {
      accept: "*/*",
      ...headersObj,
    };
    return ECAxiosAPI.post(
      `${typeof baseUrl === "undefined" ? api_url : baseUrl}${endPoint}`,
      data,
      { headers }
    )
      .then((res) => {
        return res === "" ? "success" : res;
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.status) {
          return 400;
        } else {
          return error?.response;
        }
      });
  };

  const updateData = async (endPoint, data, headersObj) => {
    const headers = {
      accept: "*/*",
      ...headersObj,
    };
    try {
      const res = await ECAxiosAPI.put(`${api_url}${endPoint}`, data, {
        headers,
      });
      return res === "" ? "success" : res;
    } catch (error) {
      // console.log(error);
      return error.response;
      errorHandler(endPoint, error);
    }
  };

  const deleteData = async (endPoint, headersObj) => {
    const headers = {
      accept: "*/*",
      ...headersObj,
    };
    try {
      const res = await ECAxiosAPI.delete(`${api_url}${endPoint}`, { headers });
      return res;
    } catch (error) {
      errorHandler(endPoint, error);
    }
  };

  // helpers
  const errorHandler = (apiName, err) => {
    // if (apiName.includes("report-actual-year")) {
    //   setIsSpotEmpty(true);
    //   localStorage.setItem("isSpotEmpty", "true");
    // }
    // localStorage.setItem("isSpotEmpty", "true");
    console.error(`${apiName} failed with: `, err.message);
    if (err.url) {
      return (window.location.href = err.url);
    }
  };

  return (
    <APIContext.Provider
      value={{
        getData,
        getTokenApi,
        postData,
        updateData,
        deleteData,
        isSpotEmpty,
        setIsSpotEmpty,
        cardIframeLink,
        setCardIframeLink,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;

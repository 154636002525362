import { MenuItem, Select, Box } from "@mui/material";
import { EcAuthContext } from "@eclear-ag/ec-auth";
import React, { useContext } from "react";
import ReactCountryFlag from "react-country-flag";
import DropDownArrowIcon from "../assets/icons/DropDownArrowIcon";
import { formattedMessage } from "../configuration/helpers";

export default function LanguageSelect(props) {
  const { userData, updateLang } = useContext(EcAuthContext);

  return (
    <Select
      style={{
        ...props.style,
        width: "35px",
        marginTop: "5px",
      }}
      className="language-select"
      title={formattedMessage(userData.lang, "Select language")}
      size="small"
      variant="standard"
      disableUnderline
      value={userData.lang}
      onChange={(e) => {
        updateLang(e.target.value);
      }}
      inputProps={{
        "aria-label": "Select language",
        id: "language-select",
      }}
      IconComponent={(props) => (
        <DropDownArrowIcon
          className={`material-icons ${props.className}`}
          style={{ marginTop: "3px" }}
        />
      )}
    >
      <MenuItem
        value="de"
        aria-label="German"
        title={formattedMessage(userData.lang, "German")}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactCountryFlag
            alt="German flag"
            countryCode={"de"}
            svg
            style={{ width: "auto", height: "16px" }}
          />
        </Box>
      </MenuItem>
      <MenuItem
        value="en"
        aria-label="English"
        title={formattedMessage(userData.lang, "English")}
      >
        {" "}
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactCountryFlag
            alt="United Kingdom flag"
            countryCode={"gb"}
            svg
            style={{ width: "auto", height: "16px" }}
          />
        </Box>
      </MenuItem>
    </Select>
  );
}

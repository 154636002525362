export const incorrect_vat_number = [
  {
    title: "VAT ID could not be verified",
    messages: [
      {
        text: "VAT ID unregistered",
        type: "error",
      },
    ],
  },
];

export const merchant_vat_id_already_used = [
  {
    title: "VAT ID already used",
    messages: [
      {
        text: "VAT ID already used.description",
        type: "error",
      },
    ],
  },
];

export const error_saving = [
  {
    title: "saveCompanyData.error.title",
    messages: [
      {
        text: "saveCompanyData.error.message",
        type: "error",
      },
    ],
  },
];

export const user_does_not_exist = [
  {
    title: "user_does_not_exist.title",
    messages: [
      {
        text: "user_does_not_exist.text",
        type: "error",
      },
    ],
  },
];

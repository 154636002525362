import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactInputVerificationCode from "react-input-verification-code";
import {
  Button,
  Input,
  Typography,
  Divider,
  useMediaQuery,
  CircularProgress,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import ValidationMessage from "../../components/validationMessage";
import {
  emailSendSuccess,
  errorMsg,
  invalidCodeError,
  passwordSubmitError,
} from "./validation_messages";
import { resetPassword, submitNewPassword } from "../../services/register";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import messages_en from "../../translations/en.json";
import messages_de from "../../translations/de.json";

const submitButtonInit = {
  isLoading: false,
  isDisabled: true,
};

export default function VerificationCode({ email }) {
  const [passValidationMessages, setValidationMessages] = useState(errorMsg);
  const [isValidateVisible, setIsValidateVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(submitButtonInit);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = React.useState({
    code: "",
    passwords: {
      password: "",
      confirmPassword: "",
    },
  });

  const messages = {
    en: messages_en,
    de: messages_de,
  };

  const theme = useTheme();
  const isWidthDown920 = useMediaQuery(theme.breakpoints.down(920));

  useEffect(() => {
    formData.code !== "" &&
    formData.passwords.password !== "" &&
    formData.passwords.confirmPassword !== ""
      ? setSubmitButtonState({ ...submitButtonState, isDisabled: false })
      : setSubmitButtonState({ ...submitButtonState, isDisabled: true });

    if (!validatePassword(formData.passwords)) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [formData]);

  const validatePassword = (passwords) => {
    const pass = passwords.password;
    //Check length of password
    if (pass.length >= 8) {
      errorMsg[0].messages[0].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[0].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if password has capital AND small letters in it
    if (/[A-Z]/.test(pass) && /[a-z]/.test(pass)) {
      errorMsg[0].messages[1].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[1].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if passsword has any digits
    if (/\d+/.test(pass)) {
      errorMsg[0].messages[2].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[2].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if passsword has any special character
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)) {
      errorMsg[0].messages[3].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[3].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if PASSWORD and REPEAT PASSWORD match
    let matchFlag = false;
    if (
      passwords.password === passwords.confirmPassword &&
      passwords.password !== "" &&
      passwords.confirmPassword !== ""
    ) {
      errorMsg[0].messages[4].type = "success";
      setValidationMessages(errorMsg);
      matchFlag = true;
    } else {
      errorMsg[0].messages[4].type = "error";
      setValidationMessages(errorMsg);
      matchFlag = false;
    }
    //validate the verification code
    if (/^[0-9]/.test(formData.code) && formData.code.length === 6) {
      errorMsg[0].messages[5].type = "success";
      setValidationMessages(errorMsg);
      matchFlag = true;
    } else {
      errorMsg[0].messages[5].type = "error";
      setValidationMessages(errorMsg);
      matchFlag = false;
    }
    //If all conditins are met, the form is valid.
    if (
      pass.length >= 8 &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      /\d+/.test(pass) &&
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass) &&
      /^[0-9]/.test(formData.code) &&
      matchFlag
    ) {
      return true;
    } else {
      return false;
    }
  };

  const navigate = useNavigate();

  const handleSubmitNewPass = async (e) => {
    e.preventDefault();
    setSubmitButtonState({
      isDisabled: true,
      isLoading: true,
    });
    if (isFormValid) {
      const res = await submitNewPassword(
        email,
        formData.code,
        formData.passwords.password
      );
      switch (res) {
        case "success":
          enqueueSnackbar(
            messages[localStorage.getItem("lang") || "de"][
              "Password reset successful"
            ],
            {
              variant: "success",
            }
          );
          navigate("/login");
          break;
        case "Invalid data exception incorrect_email_or_code":
          setValidationMessages(invalidCodeError);
          setIsValidateVisible(true);
          break;
        default:
          setValidationMessages(passwordSubmitError);
          setIsValidateVisible(true);
          break;
      }
    } else {
      setIsValidateVisible(true);
    }
    setSubmitButtonState({
      isDisabled: false,
      isLoading: false,
    });
  };

  const resendVerifyUserCode = async () => {
    setIsResendingCode(true);
    const res = await resetPassword(email);
    if (res === "success") {
      setValidationMessages(emailSendSuccess);
      setIsValidateVisible(true);
    } else {
      setValidationMessages(passwordSubmitError);
      setIsValidateVisible(true);
    }
    setIsResendingCode(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmitNewPass}
      sx={{ width: isWidthDown920 ? "85%" : "50%" }}
    >
      <Box
        style={{
          textAlign: "left",
          borderRadius: "8px",
          alignSelf: "center",
          margin: "0 auto",
        }}
        className="signup-page ec-box"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            p: "22px 20px 12px 20px",
          }}
        >
          <Box>
            <Typography fontWeight={500} fontSize={"20px"} component="p">
              <FormattedMessage id="updatePassword.title" />
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={"16px"}
              maxWidth={600}
              component="p"
            >
              <FormattedMessage id="updatePassword.subtitle" />
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: "20px 20px 0 20px", textAlign: "left" }}>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ pb: 1, pl: 0, pt: 2 }}
          >
            <FormattedMessage id="verify.fieldTitle" />*
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
            className="code-verification-input"
          >
            <ReactInputVerificationCode
              placeholder="-"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  code: e.replace(/-/g, ""),
                });
              }}
              length={6}
            />
          </div>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ pb: 1, pl: 0, pt: 2 }}
          >
            <FormattedMessage id="password" />*
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderRadius: "4px",
            }}
            className="MuiInput-input"
          >
            <Input
              id="password"
              name="password"
              className="onbarding-input"
              placeholder="••••••••"
              style={{
                flex: 1,
              }}
              disableUnderline
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  passwords: {
                    ...formData.passwords,
                    password: e.target.value,
                  },
                });
              }}
            />
            <IconButton
              color="primary"
              component="span"
              style={{
                width: "2rem",
                height: "2rem",
                float: "right",
                marginRight: "5px",
              }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <VisibilityIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              ) : (
                <VisibilityOffIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              )}
            </IconButton>
          </div>

          <Typography
            variant="subtitle2"
            component="span"
            sx={{ pb: 1, pl: 0, pt: 2 }}
          >
            <FormattedMessage id="passwordRepeat" />*
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderRadius: "4px",
            }}
            className="MuiInput-input"
          >
            <Input
              id="confirm-password"
              name="confirmPassword"
              className="onbarding-input"
              placeholder="••••••••"
              style={{
                flex: 1,
              }}
              disableUnderline
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  passwords: {
                    ...formData.passwords,
                    confirmPassword: e.target.value,
                  },
                });
              }}
            />
            <IconButton
              color="primary"
              component="span"
              style={{
                width: "2rem",
                height: "2rem",
                float: "right",
                marginRight: "5px",
              }}
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showConfirmPassword ? (
                <VisibilityIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              ) : (
                <VisibilityOffIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              )}
            </IconButton>
          </div>

          <ValidationMessage
            visibility={isValidateVisible}
            setVisibility={setIsValidateVisible}
            messages={passValidationMessages}
          />

          <Typography
            component="p"
            sx={{ mt: "20px", fontSize: "14px", mb: "30px" }}
          >
            <FormattedMessage id="verify.getEmail" />
            &nbsp;
            <span
              style={{ pointerEvents: isResendingCode && "none" }}
              className={isResendingCode ? "pulsate" : ""}
            >
              <strong
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={resendVerifyUserCode}
              >
                <FormattedMessage id="verify.here" />
              </strong>
            </span>
            &nbsp;
            <FormattedMessage id="verify.resend" />
          </Typography>

          <Box sx={{ textAlign: "center" }}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              fullWidth={true}
              sx={{
                fontSize: "16px",
                p: "12px",
                textTransform: "initial",
                mb: "20px",
              }}
              type="submit"
              onClick={handleSubmitNewPass}
              disabled={submitButtonState.isDisabled}
            >
              {submitButtonState.isLoading ? (
                <CircularProgress style={{ width: "28px", height: "28px" }} />
              ) : (
                <FormattedMessage id="updatePassword.title" />
              )}
            </Button>
            <RouterLink to="/login">
              <Button
                sx={{
                  fontSize: "12px",
                  textTransform: "initial",
                  mb: "16px",
                }}
              >
                <FormattedMessage id="cancel" />
              </Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      <Box style={{ textAlign: "center", marginTop: "4rem" }}></Box>
    </Box>
  );
}

import React from "react";
import { Box, Collapse, Typography, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";

const FormTextField = ({
  errors,
  label,
  required,
  width = 12,
  disabled,
  validation,
  validationMessage = "",
  vatIdValidation,
  value = "",
  control,
}) => {
  return (
    <Box className={`widget-col-${width}`}>
      <div>
        <label>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ pb: 1, pl: 0, opacity: 1 }}
          >
            <FormattedMessage id={label} />
            {required ? "*" : ""}
          </Typography>
        </label>
      </div>
      <Controller
        name={label}
        control={control}
        rules={{ required: required, validate: { ...validation } }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <TextField
            disabled={disabled}
            ref={ref}
            color="primary"
            fullWidth
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              ".MuiFilledInput-input": {
                pt: "8px",
              },
              ".MuiInputBase-input": {
                borderRadius: "4px",
              },
              ".MuiInputBase-root": {
                borderRadius: "4px",
              },
            }}
            // error={errors[label]?.type === "required"}
            disabled={disabled}
            name={label}
            onChange={onChange}
            value={value}
            // {...register(label, {
            //   required: required ? true : false,
            //   validate: { ...validation },
            // })}
          />
        )}
      />

      <div
        style={{
          fontWeight: 400,
          fontSize: "14px",
        }}
      >
        <Collapse in={errors[label]?.type === "required"}>
          <span className="red">
            <FormattedMessage id={label} />{" "}
            <FormattedMessage id={"isRequired"} />
          </span>
        </Collapse>

        <Collapse in={errors[label]?.type === "matchPattern"}>
          <span className="red">{validationMessage}</span>
        </Collapse>

        <Collapse in={vatIdValidation?.valid && value}>
          <span className="green">
            <React.Fragment>
              <FormattedMessage id="vatIdIsValidFor" />{" "}
              <FormattedMessage id={vatIdValidation?.country || "undefined"} />
            </React.Fragment>
          </span>
        </Collapse>

        <Collapse
          in={
            !vatIdValidation?.valid &&
            errors[label]?.type === "vatIdValidation" &&
            value
          }
        >
          <span className="red">
            <FormattedMessage id={"vatIdNotCorrect"} />
          </span>
        </Collapse>
      </div>
    </Box>
  );
};

export default FormTextField;

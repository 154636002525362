import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Paper, CircularProgress, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  Input,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import ValidationMessage from "../../components/validationMessage";
import { emailErrorMessages } from "./validation_messages";
import { error_message } from "../../helpers/validation_messages";
import { resetPassword } from "../../services/register";
import VerificationCode from "./VerificationCode";
import { useTheme } from "@mui/material/styles";

export default function Email(props) {
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [validationMessages, setValidationMessages] =
    useState(emailErrorMessages);

  const [isValidateVisible, setIsValidateVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const [screen, setScreen] = useState("email");

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isWidthDown920 = useMediaQuery(theme.breakpoints.down(920));

  useEffect(() => {
    email === "" ? setIsLoginBtnDisabled(true) : setIsLoginBtnDisabled(false);
  }, [email]);

  function validateEmail(mail) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)) {
      emailErrorMessages[0].messages[0].type = "success";
      setValidationMessages(emailErrorMessages);
      setIsFormValid(true);
    } else {
      emailErrorMessages[0].messages[0].type = "error";
      setValidationMessages(emailErrorMessages);
      setIsFormValid(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    validateEmail(email);
    if (isFormValid) {
      const res = await resetPassword(email);
      setIsValidateVisible(false);
      if (res === "success") {
        setScreen("code");
      } else {
        setValidationMessages(error_message(res));
        setIsValidateVisible(true);
      }
    } else {
      setIsValidateVisible(true);
    }
    setIsLoading(false);
  };

  const navigate = useNavigate();

  return (
    <>
      {screen === "email" ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          style={{
            textAlign: "center",
            borderRadius: "8px",
            alignSelf: "center",
            margin: "0 auto",
            width: isWidthDown920 ? "85%" : "370px",
          }}
          className="signup-page"
        >
          <Paper className="ec-box" elevation={0} style={{ textAlign: "left" }}>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                fontWeight={500}
                fontSize={"20px"}
                component="span"
                sx={{ pb: 1, pl: 0 }}
              >
                <FormattedMessage id="forgotPassword" />
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={"16px"}
                component="span"
                sx={{ pb: 1, pl: 0 }}
              >
                <FormattedMessage id="forgotPassword.description" />
              </Typography>
            </div>
            <Divider />
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="subtitle2"
                component="span"
                sx={{ pb: 1, pl: 0 }}
              >
                <FormattedMessage id="email" />
              </Typography>
              <FormControl required>
                <Input
                  autoComplete="email@exmaple.com"
                  id="email"
                  name="email"
                  className="onbarding-input"
                  placeholder="email@exmaple.com"
                  disableUnderline
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  inputProps={{
                    "aria-label": "Email",
                    "aria-required": "true",
                  }}
                />
              </FormControl>
              <ValidationMessage
                visibility={isValidateVisible}
                setVisibility={setIsValidateVisible}
                messages={validationMessages}
              />

              <Box sx={{ mt: 3, textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "initial",
                    p: "12px",
                  }}
                  disableElevation
                  type="submit"
                  color="secondary"
                  disabled={isLoginBtnDisabled || isLoading}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      <FormattedMessage id="sendEmail" />
                    )}
                  </div>
                </Button>
              </Box>
              <RouterLink to={"/login"}>
                <Box sx={{ mt: 3, textAlign: "center" }}>
                  <Typography
                    variant="caption"
                    component="span"
                    fontWeight={400}
                  >
                    <FormattedMessage id="backToLogin" />
                  </Typography>
                </Box>
              </RouterLink>
            </div>
          </Paper>
        </Box>
      ) : (
        <VerificationCode email={email} />
      )}
    </>
  );
}

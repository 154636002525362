import { EcAuthContext } from "@eclear-ag/ec-auth";
import React, { useContext, useEffect } from "react";
import Loading from "../../components/loading";
import { FormattedMessage } from "react-intl";

const LoadingScreen = () => {
  const { isUserAuthenticated, currentUserStatus, getUserStatus } =
    React.useContext(EcAuthContext);

  useEffect(() => {
    setTimeout(() => {
      getUserStatus();
      currentUserStatus === "PAYMENT_METHOD_CONFIRMED" &&
        isUserAuthenticated(true);
      window.location.href = "/spot";
    }, 10000);
  }, []);

  return (
    <div>
      <Loading />
      <h3>
        <FormattedMessage id="settingUpYourAccount" />
      </h3>
    </div>
  );
};
export default LoadingScreen;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ECAxiosAPI, EcAuthContext } from "@eclear-ag/ec-auth";
import AutoFillContext from "../../services/auto-fill-signup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  Paper,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ValidationMessage from "../../components/validationMessage";
import AGB from "../../assets/documents/HändlerAGB_Stand 04_22_01_ska.pdf";
import BGB from "../../assets/documents/eClear_Order_Processing_Agreement.pdf";
import { useTheme } from "@mui/material/styles";
import OnBoardingImage from "../../assets/images/onboarding.png";
import { errorMsg, registerErrorMsg } from "./validation_messages";
import { register } from "../../services/register";
import { isJsonEmpty } from "../../helpers/json_functions";
import { formattedMessage } from "../../configuration/helpers";

const registerButtonInit = {
  isLoading: false,
  isDisabled: true,
};

const Register = ({ nextScreen, handleNextScreen }) => {
  const { autoFillData } = useContext(AutoFillContext);

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { setRegisterInfo, resetUserData, userData } =
    useContext(EcAuthContext);

  // the sate of the form
  const [isFormValid, setIsFormValid] = useState(false);
  // Forms elements
  const [formData, setFormData] = useState({
    email: "",
    passwords: {
      password: "",
      confirmPassword: "",
    },
    agbs: false,
    bgb: false,
  });
  const [showPassword, setShwoPassword] = useState(false);
  const [showConfirmPassword, setShwoConfirmPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState(errorMsg);
  const [isValidateVisible, setIsValidateVisible] = useState(false);
  const [registerButtonState, setRegisterButtonState] =
    useState(registerButtonInit);

  const isInvite = searchParams.get("type") === "invite";

  // handle register button state
  useEffect(() => {
    validateEmail(formData.email);
    validatePassword(formData.passwords);

    const isFormIncomplete = () => {
      return (
        formData.email === "" ||
        formData.passwords.password === "" ||
        formData.passwords.confirmPassword === "" ||
        formData.agbs === false ||
        formData.bgb === false
      );
    };

    setRegisterButtonState(
      isFormIncomplete()
        ? registerButtonInit
        : { isLoading: false, isDisabled: false }
    );

    // validate form and set validation messages
    if (
      !validateEmail(formData.email) ||
      !validatePassword(formData.passwords)
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [formData]);

  const validateEmail = (email) => {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      errorMsg[0].messages[0].type = "success";
      setValidationMessages(errorMsg);
      return true;
    } else {
      errorMsg[0].messages[0].type = "error";
      setValidationMessages(errorMsg);
      return false;
    }
  };

  const validatePassword = (passwords) => {
    const pass = passwords.password;
    //Check length of password
    if (pass.length >= 8) {
      errorMsg[0].messages[1].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[1].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if password has capital AND small letters in it
    if (/[A-Z]/.test(pass) && /[a-z]/.test(pass)) {
      errorMsg[0].messages[2].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[2].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if passsword has any digits
    if (/\d+/.test(pass)) {
      errorMsg[0].messages[3].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[3].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if passsword has any special character
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)) {
      errorMsg[0].messages[4].type = "success";
      setValidationMessages(errorMsg);
    } else {
      errorMsg[0].messages[4].type = "error";
      setValidationMessages(errorMsg);
    }
    //Check if PASSWORD and REPEAT PASSWORD match
    let matchFlag = false;
    if (
      passwords.password === passwords.confirmPassword &&
      passwords.password !== "" &&
      passwords.confirmPassword !== ""
    ) {
      errorMsg[0].messages[5].type = "success";
      setValidationMessages(errorMsg);
      matchFlag = true;
    } else {
      errorMsg[0].messages[5].type = "error";
      setValidationMessages(errorMsg);
      matchFlag = false;
    }
    //If all conditins are met, the form is valid.
    if (
      pass.length >= 8 &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      /\d+/.test(pass) &&
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass) &&
      matchFlag
    ) {
      return true;
    } else {
      return false;
    }
  };

  // handle submit is checking if the form valid then submits the form or shwoing the error message if form not valid
  const handleSubmit = async (e) => {
    localStorage.removeItem("invoiceData");
    setRegisterButtonState({
      isLoading: true,
      isDisabled: true,
    });
    e.preventDefault();
    resetUserData();
    if (isFormValid) {
      setRegisterInfo({
        username: formData.email,
        password: formData.passwords.password,
      });
      try {
        const registerUser = await register(
          formData.email,
          formData.passwords.password
        );
        if (registerUser === 200) {
          const searchParamsObj = Object.fromEntries(
            new URLSearchParams(searchParams)
          );
          handleNextScreen({
            ...searchParamsObj,
            email: formData.email,
            screen: nextScreen,
          });
        } else {
          registerErrorMsg[0].messages[0].type = "error";
          setValidationMessages(registerErrorMsg);
          setIsValidateVisible(true);
        }
      } catch (err) {
        console.error("Register failed:", err);
        registerErrorMsg[0].messages[0].type = "error";
        setValidationMessages(registerErrorMsg);
        setIsValidateVisible(true);
      }
    } else {
      setIsValidateVisible(true);
    }
    setRegisterButtonState({
      isLoading: false,
      isDisabled: false,
    });
  };

  useEffect(() => {
    !isJsonEmpty(autoFillData) &&
      setFormData({
        ...formData,
        email: autoFillData.email,
      });
  }, [autoFillData]);

  //Everytime the validation messages are changed, check if any of the messages contains "type:error"
  //This is to ensure that once all fields are correct, the validatoin box must be hidden in 5 seconds.
  useEffect(() => {
    !validationMessages[0].messages.some((obj) => obj.type === "error") &&
      setTimeout(() => {
        setIsValidateVisible(false);
      }, 5000);
  }, [validationMessages]);

  return (
    <Grid
      container
      gap={isTabletScreen ? 0 : 6}
      alignItems="center"
      direction="row"
    >
      <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
        <img
          alt="SPOT Preview"
          src={OnBoardingImage}
          style={{
            width: "100%",
            objectFit: "contain",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        order={{ xs: 1, md: 2 }}
        style={{ padding: "25px" }}
      >
        <Paper
          className="ec-box"
          elevation={0}
          style={{
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              p: "22px 20px 12px 20px",
            }}
          >
            <Box>
              <Typography fontWeight={500} fontSize={"20px"} component="p">
                <FormattedMessage id="register" />
              </Typography>
              <Typography fontWeight={400} fontSize={"16px"} component="p">
                <FormattedMessage id="enterEmailPass" />
              </Typography>
            </Box>
            {!isInvite && (
              <Typography
                variant="h2"
                sx={{
                  fontSize: "40px",
                  fontWeight: "500",
                  m: "auto 0 0 0",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                0€<sub style={{ position: "relative", top: "-10px" }}>*</sub>
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "auto",
                    marginBottom: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <FormattedMessage id="for90Days" />
                </span>
              </Typography>
            )}
          </Box>
          <Divider />
          <Box sx={{ p: "20px 20px 0 20px" }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{ pb: 1, pl: 0 }}
                >
                  <FormattedMessage id="email" />*
                </Typography>
                <FormControl required>
                  <Input
                    id="email"
                    name="email"
                    className="onbarding-input"
                    placeholder="email@example.com"
                    disableUnderline
                    value={formData.email}
                    onChange={(e) => {
                      const email = e.target.value.replace(/\s/g, "");
                      setFormData({ ...formData, email });
                    }}
                    type="email"
                    inputProps={{
                      "aria-label": "Email",
                      "aria-required": "true",
                    }}
                  />
                </FormControl>

                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{ pb: 1, pl: 0, pt: 2 }}
                >
                  <FormattedMessage id="password" />*
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  className="MuiInput-input"
                >
                  <Input
                    id="password"
                    name="password"
                    className="onbarding-input"
                    placeholder="••••••••"
                    style={{
                      flex: 1,
                    }}
                    disableUnderline
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        passwords: {
                          ...formData.passwords,
                          password: e.target.value,
                        },
                      });
                    }}
                    inputProps={{
                      "aria-label": "Password",
                      "aria-required": "true",
                    }}
                  />
                  <IconButton
                    title={
                      showPassword
                        ? formattedMessage(userData.lang, "Hide password")
                        : formattedMessage(userData.lang, "Show password")
                    }
                    aria-label="Toggle password visibility"
                    color="primary"
                    component="span"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      float: "right",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setShwoPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <VisibilityIcon
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </IconButton>
                </div>

                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{ pb: 1, pl: 0, pt: 2 }}
                >
                  <FormattedMessage id="passwordRepeat" />*
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                  className="MuiInput-input"
                >
                  <Input
                    id="confirm-password"
                    name="confirmPassword"
                    className="onbarding-input"
                    placeholder="••••••••"
                    style={{
                      flex: 1,
                    }}
                    disableUnderline
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        passwords: {
                          ...formData.passwords,
                          confirmPassword: e.target.value,
                        },
                      });
                    }}
                    inputProps={{
                      "aria-label": "Confirm Password",
                      "aria-required": "true",
                    }}
                  />
                  <IconButton
                    title={
                      showPassword
                        ? formattedMessage(userData.lang, "Hide password")
                        : formattedMessage(userData.lang, "Show password")
                    }
                    aria-label="Toggle password visibility"
                    color="primary"
                    component="span"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      float: "right",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setShwoConfirmPassword(!showConfirmPassword);
                    }}
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </IconButton>
                </div>

                <React.Fragment>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "1rem",
                    }}
                  >
                    <Checkbox
                      aria-label="AGB"
                      name="agbs"
                      size="small"
                      checked={formData.agbs}
                      onClick={() =>
                        setFormData({ ...formData, agbs: !formData.agbs })
                      }
                    />
                    <Typography
                      variant="subtitle2"
                      component="span"
                      fontWeight={400}
                      marginTop={"9px"}
                      style={{ opacity: 0.6 }}
                    >
                      <FormattedMessage id="iHaveTakenNote" />{" "}
                      <a
                        href="https://www.eclear.com/agb"
                        rel="noreferrer noopener"
                        onClick={() => {
                          window.open(AGB, "_blank");
                        }}
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <FormattedMessage id="generalTermsAndConditions" />
                      </a>{" "}
                      {!isInvite && (
                        <React.Fragment>
                          <FormattedMessage id="andThe" />{" "}
                          <a
                            href="https://www.eclear.com/agb"
                            rel="noreferrer noopener"
                            onClick={() => {
                              window.open(BGB, "_blank");
                            }}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <FormattedMessage id="orderProcessingAgreement" />
                          </a>{" "}
                        </React.Fragment>
                      )}
                      <FormattedMessage id="andAgree" />
                    </Typography>
                  </label>

                  {/* SECOND CB */}
                  <label
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Checkbox
                      aria-label="BGB"
                      name="bgb"
                      size="small"
                      checked={formData.bgb}
                      onClick={() =>
                        setFormData({ ...formData, bgb: !formData.bgb })
                      }
                    />
                    <Typography
                      variant="subtitle2"
                      component="span"
                      fontWeight={400}
                      marginTop={"9px"}
                      style={{ opacity: 0.6 }}
                    >
                      <FormattedMessage id="acceptBgb" />
                    </Typography>
                  </label>
                </React.Fragment>
              </div>

              <div
                //check the type of validation message and based on that use the corresponding container class. Refer to signup.css for more info
                //This block also contains the logic to check if any of the objects inside the "messages" key, in validationMessages state variable, contains "type:error". Since "messages" is an array, therefore aray function "some" has been used
                //If none of the objects in the "messages" array contains "type:error" (meaning that form is valid) then apply the css class "success"
                className={`${
                  !validationMessages[0].messages.some(
                    (obj) => obj.type === "error"
                  ) && "success"
                }`}
              >
                <ValidationMessage
                  visibility={isValidateVisible}
                  setVisibility={setIsValidateVisible}
                  messages={validationMessages}
                />
              </div>

              <Box sx={{ textAlign: "center", mt: "20px" }}>
                <Button
                  disableElevation
                  id="startButton"
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  sx={{
                    fontSize: "16px",
                    p: "12px",
                    textTransform: "initial",
                    mb: "20px",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={registerButtonState.isDisabled}
                >
                  {registerButtonState.isLoading ? (
                    <CircularProgress
                      style={{ width: "28px", height: "28px" }}
                    />
                  ) : (
                    <FormattedMessage id="register" />
                  )}
                </Button>
              </Box>
            </form>
            <Box
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                style={{ opacity: 0.6 }}
                sx={{
                  fontSize: "12px",
                  textTransform: "initial",
                  mb: "16px",
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <FormattedMessage id="cancel" />
              </Button>
            </Box>
          </Box>
          {!isInvite && (
            <React.Fragment>
              <Divider />
              <div
                style={{
                  paddingBottom: "20px",
                  fontSize: "14px",
                  padding: "20px 0px 0px 20px",
                }}
              >
                <span style={{ opacity: 0.6 }}>
                  <FormattedMessage id="required" />
                </span>
              </div>
              <Typography
                component="p"
                sx={{
                  p: "20px",
                  m: 0,
                  fontSize: "14px",
                  opacity: 0.6,
                }}
              >
                **
                <FormattedMessage id="after90Days" />
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Register;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Box } from "@mui/system";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  Typography,
  Link,
  Paper,
} from "@mui/material";

import ValidationMessage from "../../components/validationMessage";
import Loading from "../../components/loading";

import { EcAuthContext } from "@eclear-ag/ec-auth";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OnBoardingImage from "../../assets/images/onboarding.png";
import { errorMessages } from "./validation_messages";

export default function Login() {
  const {
    signin,
    authUser,
    currentUserStatus,
    resetUserData,
    productStatusInfos,
  } = React.useContext(EcAuthContext);
  const navigate = useNavigate();

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [validationMessages, setValidationMessages] = useState(errorMessages);
  const [password, setPassword] = useState("");
  const [isValidateVisible, setIsValidateVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState("error");
  const [screenType, setScreenType] = useState("sign-in");

  useEffect(() => {
    email === "" || password === ""
      ? setIsLoginBtnDisabled(true)
      : setIsLoginBtnDisabled(false);
    validationMessages[0].title === "loginFailed" &&
      setIsValidateVisible(false);
    validateForm(email, password);
  }, [email, password]);

  useEffect(() => {
    if (screenType === "loading") {
      // setTimeout(() => {
      //   setScreenType("sign-in");
      // }, 2000);
      setIsValidateVisible(true);
      setValidationMessages([
        {
          inputName: "form",
          title: "loginFailed",
          messages: [
            {
              text: "loginFailed.error",
              type: "error",
            },
          ],
          type: "error",
        },
      ]);
      setIsFormValid(false);
    }
  }, [screenType]);

  useEffect(() => {
    currentUserStatus !== "" && navigateToScreen();
  }, [currentUserStatus]);

  const navigateToScreen = () => {
    let a = window.location.href;
    let b = a.substring(a.indexOf("?") + 1);
    // console.log("currentUserStatus: ", currentUserStatus);
    switch (currentUserStatus) {
      case "REGISTER":
        navigate(`/signup?screen=company&${b}`);
        break;
      case "VERIFIED":
        productStatusInfos.length === 0
          ? navigate(`/signup?screen=company&${b}`)
          : navigate(`/signup?screen=user&${b}`);
        break;
      case "COMPANY_INFO":
        navigate(`/signup?screen=user&${b}`);
        break;
      case "USER_INFO":
        navigate(`/signup/select-payment-method`);
        break;
      case "COMPLETED":
        productStatusInfos.length === 0
          ? navigate(`/signup/select-payment-method`)
          : (window.location.href = "/spot/");
        break;
      case "PAYMENT_METHOD_PENDING":
        navigate(`/signup/select-payment-method`);
        break;
      case "DASHBOARD":
        navigate(`/signup/select-payment-method`);
        break;
      case "PAYMENT_METHOD_CONFIRMED":
        window.location.href = "/spot/";
        break;
      default:
        navigate(`/`);
        break;
    }
  };

  async function handleSubmit() {
    localStorage.removeItem("invoiceData");
    if (isFormValid === false) {
      setIsValidateVisible(true);
    } else {
      setIsValidateVisible(false);
      setScreenType("loading");

      try {
        resetUserData();
        const res = await signin(email, password);
        if (res) {
          if (res === "NotAuthorizedException") {
            setIsValidateVisible(true);
            setScreenType("sign-in");
            return;
          }
          await authUser();
          // navigateToScreen()
        }
        if (res === "UNVERIFIED") {
          navigate(`/signup?screen=verify&email=${email}`);
        } else {
          setIsValidateVisible(true);
          setScreenType("sign-in");
        }
      } catch (err) {
        console.error("sign in failed", err.code);
      }
    }
  }

  function validateForm(email, password) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      errorMessages[0].messages[0].type = "success";
      errorMessages[0].messages[0].text = "email.success";
      setValidationMessages(errorMessages);
    } else {
      errorMessages[0].messages[0].text = "email.error";
      errorMessages[0].messages[0].type = "error";
      setValidationMessages(errorMessages);
    }

    if (password.length >= 8) {
      errorMessages[0].messages[1].type = "success";
      setValidationMessages(errorMessages);
    } else {
      errorMessages[0].messages[1].type = "error";
      setValidationMessages(errorMessages);
    }

    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) &&
      password.length >= 8
    ) {
      errorMessages[0].title = "allGood";
      setIsFormValid(true);
    } else {
      errorMessages[0].title = "somethingIsNotRight";
      setIsFormValid(false);
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        gap={isTabletScreen ? 0 : 6}
        alignItems="center"
        direction="row"
        className="page-container"
      >
        <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
          <img
            alt="SPOT Preview"
            src={OnBoardingImage}
            style={{
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ p: 3 }} order={{ xs: 1, md: 2 }}>
          <Paper
            className="ec-box"
            elevation={0}
            style={{
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Typography
              component="p"
              sx={{ p: "12px 16px", m: 0, fontWeight: 500, fontSize: "20px" }}
            >
              {screenType === "loading" ? (
                <FormattedMessage id="LoginInProgress" />
              ) : (
                <FormattedMessage id="loginTitle" />
              )}
            </Typography>
            <Divider />
            {screenType === "sign-in" && (
              <>
                <form style={{ textAlign: "left" }}>
                  <div
                    style={{
                      padding: "1rem 1rem 0rem 1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      component="span"
                      sx={{ pb: 1, pl: 0 }}
                    >
                      <FormattedMessage id="email" />
                    </Typography>
                    <FormControl required>
                      <Input
                        color="primary"
                        id="email"
                        name="email"
                        className="onbarding-input"
                        autoComplete="email@example.com"
                        placeholder="email@example.com"
                        disableUnderline
                        value={email}
                        onChange={(e) => {
                          const emailValue = e.target.value.replace(/\s/g, "");
                          setEmail(emailValue);
                        }}
                        inputProps={{
                          "aria-label": "Email",
                          "aria-required": "true",
                        }}
                      />
                    </FormControl>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      sx={{ pb: 1, pl: 0, pt: 2 }}
                    >
                      <FormattedMessage id="password" />
                    </Typography>
                    <FormControl required>
                      <Input
                        color="primary"
                        id="password"
                        name="password"
                        className="onbarding-input"
                        autoComplete="••••••••"
                        placeholder="••••••••"
                        disableUnderline
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        inputProps={{
                          "aria-label": "Password",
                          "aria-required": "true",
                        }}
                      />
                    </FormControl>

                    <Link
                      style={{
                        fontSize: "12px",
                        padding: "8px 0px 16px 0px",
                      }}
                    >
                      <RouterLink
                        aria-label="Forgot password"
                        to={"/forgot-password"}
                        onClick={() => {
                          setScreenType("forgot-password");
                        }}
                      >
                        <FormattedMessage id="forgotPassword" />
                      </RouterLink>
                    </Link>
                  </div>

                  <Divider />
                  <Box style={{ padding: "0rem 1rem" }}>
                    <ValidationMessage
                      visibility={isValidateVisible}
                      setVisibility={setIsValidateVisible}
                      messages={validationMessages}
                    />
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "1rem",
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight={500}
                        fontSize={16}
                        textAlign={"left"}
                      >
                        <FormattedMessage
                          id="registerForSpot"
                          values={{
                            b: (...chunks) => {
                              return (
                                <RouterLink
                                  aria-label="Sign up"
                                  to={"/signup"}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {chunks}
                                </RouterLink>
                              );
                            },
                          }}
                        />
                      </Typography>
                    </Box>
                    <Button
                      aria-label="Login"
                      color="primary"
                      variant="contained"
                      disableElevation
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      disabled={isLoginBtnDisabled}
                      style={{ fontWeight: "bold" }}
                    >
                      <FormattedMessage id="login" />
                    </Button>
                  </Box>
                </form>
              </>
            )}
            {screenType === "loading" && <Loading />}
          </Paper>
        </Grid>
      </Grid>
      {/* <FooterLink /> */}
    </Box>
  );
}
